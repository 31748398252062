<template>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center lg:px-4 mb-2"
  >
    <v-select
      :options="farmTypes"
      v-model="formData.selectFarmType"
      :reduce="(option) => option.id"
      class="style-chooser2 style-chooser5 max-w-130 md:max-w-236"
      label="name"
      placeholder=" Tipo de Finca"
    >
      <template #no-options>Sin opciones</template>
    </v-select>
  </div>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center lg:px-4"
  >
    <v-select
      :options="departments"
      v-model="formData.selectedDepart"
      :reduce="(option) => option.id"
      class="style-chooser2 style-chooser5 max-w-130 md:max-w-236"
      label="name"
      placeholder="Departamento"
    >
      <template #no-options>Sin opciones</template>
    </v-select>
  </div>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center lg:px-4"
  >
    <v-select
      :options="municipalities"
      v-model="formData.municipality_id"
      :reduce="(option) => option.id"
      class="style-chooser2 style-chooser5 max-w-130 md:max-w-236"
      label="name"
      placeholder="Municipio"
    >
      <template #no-options>Sin opciones</template>
    </v-select>
  </div>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center"
  >
    <div
      class="grid lg:col-span-2 bg-agrogo-green-5 lg:h-20 place-content-center place-items-center lg:px-2.5"
    >
      <div
        class="grid grid-cols-1 gap-y-2 md:gap-y-4 lg:grid-cols-3 lg:gap-x-2 xl:gap-x-3.5 place-items-center place-content-center"
      >
        <div
          class="grid grid-cols-3 lg:grid-cols-1 place-content-center place-items-center lg:place-items-start"
        >
          <div class="flex">
            <p class="font-nexa font-normal text-white text-sm md:text-lg">
              Área
            </p>
            <div class="tooltip-container hidden lg:flex">
              <i class="fas fa-question-circle tooltip-trigger text-white">
                <span
                  class="icon-Group text-agrogo-yellow-1 text-lg md:text-xl ml-1.5 transform transition-all"
                ></span>
              </i>
              <div class="tooltip-one">1 cuadra = 0.64 hectáreas</div>
            </div>
          </div>
          <p class="font-nexa font-normal text-gray-300 text-xs xl:text-base">
            (hectáreas)
          </p>
          <div class="tooltip-container lg:hidden">
            <i class="fas fa-question-circle tooltip-trigger text-white">
              <span
                class="icon-Group text-agrogo-yellow-1 text-lg md:text-2xl ml-1.5 transform transition-all"
              ></span>
            </i>
            <div class="tooltip-one">1 cuadra = 0.64 hectáreas</div>
          </div>
        </div>
        <div>
          <input
            id="area1"
            name="area1"
            type="text"
            v-model="formData.area1"
            @keyup="formatArea1"
            @blur="validationArea1()"
            class="input-standard-transparent text-right w-full"
            placeholder="Desde"
          />
        </div>
        <div>
          <input
            id="area2"
            name="area2"
            type="text"
            v-model="formData.area2"
            @keyup="formatArea2"
            @blur="validationArea2()"
            class="input-standard-transparent text-right w-full"
            placeholder="Hasta"
          />
        </div>
      </div>
      <p
        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
        v-if="isValidationArea1"
      >
        El area Desde no debe ser superior al Hasta.
      </p>
      <p
        class="text-xs md:text-base lg:text-base text-agrogo-yellow-2 font-nexa block text-center"
        v-if="isValidationArea2"
      >
        El area Hasta no debe ser inferior al Desde.
      </p>
    </div>
  </div>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center lg:px-4"
  >
    <v-select
      :options="status"
      v-model="formData.state"
      :reduce="(option) => option.id"
      class="style-chooser2 style-chooser5 max-w-130 md:max-w-236"
      label="name"
      placeholder="Estado"
    >
      <template #no-options>Sin opciones</template>
    </v-select>
  </div>
  <div
    class="flex bg-agrogo-green-5 w-full lg:h-20 place-content-center place-items-center lg:px-4"
  >
    <div
      class="hidden lg:flex items-center justify-center bg-agrogo-green-5 lg:h-20"
    >
      <button
        @click.prevent="filterFarms()"
        type="button"
        class="btn-login w-28 mr-3"
      >
        Aplicar
      </button>
      <button @click.prevent="clear()" type="button" class="btn-login w-28">
        Borrar
        <span class="icon-refresh text-agrogo-green-3 text-xs ml-2.5"></span>
      </button>
    </div>
  </div>
</template>

<script>
import { generateHash } from "@/utils";

export default {
  inject: ["provider"],
  emits: ["filterFarms"],
  props: {
    farmTypes: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    status: {
      type: Array,
      default: () => [],
    },
    records: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const me = this;
    return {
      repositoryFarm: me.provider.myFarmRepository,
      repository: me.provider.publishFarmRepository,
      municipalities: [],
      isValidationArea1: false,
      isValidationArea2: false,
      formData: {
        municipality_id: null,
        selectedDepart: null,
        selectFarmType: null,
        state: null,
        area1: null,
        area2: null,
      },
    };
  },
  watch: {
    "formData.selectedDepart": function () {
      const me = this;
      if (me.formData.selectedDepart) {
        me.loadMunicipies();
      } else {
        me.resetSelect();
        if (me.formData.selectedDepart == null) me.filterFarms();
      }
    },
    "formData.municipality_id": function () {
      const me = this;
      if (me.formData.municipality_id == null) me.filterFarms();
    },
    "formData.selectFarmType": function () {
      const me = this;
      if (me.formData.selectFarmType == null) me.filterFarms();
    },
    "formData.state": function () {
      const me = this;
      if (me.formData.state == null) me.filterFarms();
    },
  },
  methods: {
    async loadMunicipies() {
      const me = this;
      if (me.formData.selectedDepart !== null) {
        try {
          me.resetSelect();
          const dataAditional = await generateHash();
          const {
            data: { data },
          } = await me.repository.getAllMuniciByDepart(
            me.formData.selectedDepart,
            dataAditional
          );

          me.municipalities = data.municipalities;
        } catch (error) {
          // console.log(error);
          const code = error.response.status;
          if (code == 401) {
            me.validationExpiredToken();
          }
        }
      }
    },
    resetSelect() {
      const me = this;
      me.municipalities = [];
      me.formData.municipality_id = null;
    },
    formatArea1() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = me.formData.area1.split(",");
      if (area10.length > 2) {
        me.formData.area1 = `${area10[0]}${
          area10[1] !== "" ? `,${area10[1]}` : ","
        }`;
      }
      let area = me.formData.area1.replace(/[^\d,.]/g, "");
      let area1 = me.formData.area1.replace(/[^\d,.]/g, "");
      let attribute = 0;
      let isValidate = true;

      if (me.formData.area1.split(".")[1]) {
        area = me.formData.area1
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.formData.area1.split(",")[1]) {
        if (me.formData.area1.split(".")[1]) {
          const area3 = me.formData.area1
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area1 = formatter.format(area3);
        } else {
          const area3 = me.formData.area1.split(",")[0].replace(/[^\d,.]/g, "");
          area1 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area1.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.formData.area1
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }
      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area1;
      me.formData.area1 = attribute;
    },
    formatArea2() {
      const me = this;
      const formatter = new Intl.NumberFormat("es-CO", {
        minimumFractionDigits: 0,
      });
      const area10 = me.formData.area2.split(",");
      if (area10.length > 2) {
        me.formData.area2 = `${area10[0]}${
          area10[1] !== "" ? `,${area10[1]}` : ","
        }`;
      }
      let area = me.formData.area2.replace(/[^\d,.]/g, "");
      let area2 = me.formData.area2.replace(/[^\d,.]/g, "");
      let attribute = 0;
      let isValidate = true;

      if (me.formData.area2.split(".")[1]) {
        area = me.formData.area2
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }

      if (me.formData.area2.split(",")[1]) {
        if (me.formData.area2.split(".")[1]) {
          const area3 = me.formData.area2
            .split(",")[0]
            .split(".")
            .join("")
            .replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        } else {
          const area3 = me.formData.area2.split(",")[0].replace(/[^\d,.]/g, "");
          area2 = formatter.format(area3);
        }
        isValidate = false;
        let t = area.toString();
        let regex = /(\d*.\d{0,1})/;
        const attribute2 = t.match(regex)[0];
        attribute = `${area2.split(",")[0]},${attribute2.split(",")[1]}`;
      } else {
        area = me.formData.area2
          .split(".")
          .join("")
          .replace(/[^\d,.]/g, "");
        attribute = formatter.format(area);
      }
      if (isNaN(attribute.split(".").join("")) && isValidate) attribute = area2;
      me.formData.area2 = attribute;
    },
    validationArea1() {
      const me = this;
      let isError = false;
      let area1 = me.formData.area1;
      let area2 = me.formData.area2;

      if (me.formData.area1) {
        if (me.formData?.area1?.split(".")[1]) {
          area1 = me.formData.area1.split(".").join("");
        }

        if (me.formData?.area1?.split(",")[1]) {
          area1 = me.formData.area1.split(".").join("").split(",").join(".");
        }

        if (me.formData?.area2?.split(".")[1]) {
          area2 = me.formData.area2.split(".").join("").split(",").join(".");
        }

        if (me.formData?.area2?.split(",")[1]) {
          area2 = me.formData.area2.split(".").join("").split(",").join(".");
        }

        if (parseFloat(area1) > parseFloat(area2) && parseFloat(area2) > 0) {
          isError = true;
          me.formData.area1 = 0;
        }

        me.isValidationArea1 = isError;
      }
    },
    validationArea2() {
      const me = this;
      let isError = false;
      let area1 = me.formData.area1;
      let area2 = me.formData.area2;

      if (me.formData.area2) {
        if (me.formData?.area1?.split(".")[1]) {
          area1 = me.formData.area1.split(".").join("");
        }

        if (me.formData?.area1?.split(",")[1]) {
          area1 = me.formData.area1.split(".").join("").split(",").join(".");
        }

        if (me.formData?.area2?.split(".")[1]) {
          area2 = me.formData.area2.split(".").join("").split(",").join(".");
        }

        if (me.formData?.area2?.split(",")[1]) {
          area2 = me.formData.area2.split(".").join("").split(",").join(".");
        }

        if (parseFloat(area2) < parseFloat(area1) && parseFloat(area2) > 0) {
          isError = true;
          me.formData.area2 = 0;
        }
        me.isValidationArea2 = isError;
      }
    },
    clear() {
      const me = this;
      me.formData.municipality_id = null;
      me.formData.selectedDepart = null;
      me.formData.selectFarmType = null;
      me.formData.state = null;
      me.formData.area1 = null;
      me.formData.area2 = null;
      me.isValidationArea1 = false;
      me.isValidationArea2 = false;
      const records = me.records;
      me.$emit("filterFarms", records);
    },
    filterFarms() {
      const me = this;

      let records = [];
      let data = [];
      let data2 = [];
      let data3 = [];
      let data4 = [];
      let data5 = [];
      let data6 = [];

      // Filtro tipo de finca
      if (me.formData.selectFarmType !== null) {
        me.records.forEach((el) => {
          el.subcategory.forEach((x) => {
            if (x.id == me.formData.selectFarmType) {
              data.push(el);
            }
          });
        });
        records = data;
      } else {
        records = me.records;
      }

      // Filtro por departamento
      if (me.formData.selectedDepart !== null) {
        data2 = records.filter(
          (el) => el.departments.id == me.formData.selectedDepart
        );
        records = data2;
      }

      // Filtro por municipio
      if (me.formData.municipality_id !== null) {
        data3 = records.filter(
          (el) => el.municipalities.id == me.formData.municipality_id
        );
        records = data3;
      }

      // Filtro por area desde
      if (me.formData.area1 !== null) {
        records.forEach((el) => {
          console.log(el);
          if (
            Number(el.area.value?.split(".").join("")) >=
            Number(me.formData.area1?.split(".").join(""))
          ) {
            data4.push(el);
          }
        });
        records = data4;
      }

      // Filtro por area hasta
      if (me.formData.area2 !== null) {
        records.forEach((el) => {
          if (
            Number(el.area.value?.split(".").join("")) <=
            Number(me.formData.area2?.split(".").join(""))
          ) {
            data5.push(el);
          }
        });
        records = data5;
      }

      // Filtro por estado
      if (me.formData.state !== null) {
        records.forEach((el) => {
          // Favoritas id 55
          if (me.formData.state == 55 && el.favorite == 1) {
            if (el.favorite == 1) data6.push(el);
          } else if (el.state.id == 1 && el.favorite == 0) {
            if (el.state.id == me.formData.state && el.favorite == 0)
              data6.push(el);
          } else {
            if (el.state.id == me.formData.state && el.state.id !== 1)
              data6.push(el);
          }
        });
        records = data6;
      }
      console.log(records);
      me.$emit("filterFarms", records);
    },
  },
};
</script>

<style scoped>
input::placeholder {
  color: #e5e5e5 !important;
  opacity: 0.75;
}
</style>
